define("discourse/plugins/discourse-anonymous-moderators/discourse/initializers/discourse-anonymous-moderators", ["exports", "@ember/service", "discourse/lib/plugin-api", "discourse/lib/url", "discourse-common/lib/icon-library", "discourse/plugins/discourse-anonymous-moderators/discourse/components/anonymous-moderator-tab"], function (_exports, _service, _pluginApi, _url, _iconLibrary, _anonymousModeratorTab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  function initializeAnonymousUser(api) {
    api.registerUserMenuTab(UserMenuTab => {
      var _class, _descriptor;
      return _class = class _class extends UserMenuTab {
        constructor() {
          super(...arguments);
          _initializerDefineProperty(this, "currentUser", _descriptor, this);
          _defineProperty(this, "id", "anonymous_moderator");
          _defineProperty(this, "icon", "user-secret");
          _defineProperty(this, "panelComponent", _anonymousModeratorTab.default);
        }
        get shouldDisplay() {
          return this.currentUser?.can_become_anonymous_moderator || this.currentUser?.is_anonymous_moderator;
        }
      }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null
      })), _class;
    });
    api.decorateWidget(`poster-name:after`, dec => {
      const username = dec.attrs.userCustomFields?.parent_user_username;
      if (!username) {
        return null;
      }
      return dec.h("span.poster-parent-username", dec.h("a.anon-identity", {
        attributes: {
          "data-user-card": username,
          href: (0, _url.userPath)()
        }
      }, [(0, _iconLibrary.iconNode)("user-secret"), ` ${username}`]));
    });
  }
  var _default = _exports.default = {
    name: "discourse-anonymous-moderators",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.anonymous_moderators_enabled) {
        (0, _pluginApi.withPluginApi)("0.8", initializeAnonymousUser);
      }
    }
  };
});