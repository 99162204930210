define("discourse/plugins/discourse-anonymous-moderators/discourse/components/anonymous-moderator-tab", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajax, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="anonymous-moderator-switcher">
    <div class="anonymous-moderator-switcher__header">
      <span class="anonymous-moderator-switcher__user">
        {{i18n
          "anonymous_moderators.current_account"
          username=this.currentUser.username
        }}
      </span>
  
      <span class="anonymous-moderator-switcher__status">
        {{#if this.currentUser.can_become_anonymous_moderator}}
          {{i18n "anonymous_moderators.signed_in_main"}}
        {{else}}
          {{i18n "anonymous_moderators.signed_in_anon"}}
        {{/if}}
      </span>
    </div>
  
    {{#if this.currentUser.can_become_anonymous_moderator}}
      <DButton
        @action={{this.becomeAnonModerator}}
        @label="anonymous_moderators.switch_to_anon"
        @icon="user-secret"
        @isLoading={{this.loading}}
      />
    {{else if this.currentUser.is_anonymous_moderator}}
      <DButton
        @action={{this.becomeMasterUser}}
        @label="anonymous_moderators.switch_to_master"
        @icon="user-secret"
        @isLoading={{this.loading}}
      />
    {{/if}}
  </div>
  */
  {
    "id": "b4fH6mMO",
    "block": "[[[10,0],[14,0,\"anonymous-moderator-switcher\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"anonymous-moderator-switcher__header\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"anonymous-moderator-switcher__user\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[\"anonymous_moderators.current_account\"],[[\"username\"],[[30,0,[\"currentUser\",\"username\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,1],[14,0,\"anonymous-moderator-switcher__status\"],[12],[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"can_become_anonymous_moderator\"]],[[[1,\"        \"],[1,[28,[35,0],[\"anonymous_moderators.signed_in_main\"],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,0],[\"anonymous_moderators.signed_in_anon\"],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"currentUser\",\"can_become_anonymous_moderator\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@action\",\"@label\",\"@icon\",\"@isLoading\"],[[30,0,[\"becomeAnonModerator\"]],\"anonymous_moderators.switch_to_anon\",\"user-secret\",[30,0,[\"loading\"]]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"currentUser\",\"is_anonymous_moderator\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@action\",\"@label\",\"@icon\",\"@isLoading\"],[[30,0,[\"becomeMasterUser\"]],\"anonymous_moderators.switch_to_master\",\"user-secret\",[30,0,[\"loading\"]]]],null],[1,\"\\n  \"]],[]],null]],[]]],[13]],[],false,[\"i18n\",\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-anonymous-moderators/discourse/components/anonymous-moderator-tab.hbs",
    "isStrictMode": false
  });
  let AnonymousModeratorTab = _exports.default = (_class = class AnonymousModeratorTab extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "loading", _descriptor2, this);
    }
    async becomeAnonModerator() {
      this.loading = true;
      await (0, _ajax.ajax)("/anonymous-moderators/become-anon", {
        method: "POST"
      });
      window.location.reload();
    }
    async becomeMasterUser() {
      this.loading = true;
      await (0, _ajax.ajax)("/anonymous-moderators/become-master", {
        method: "POST"
      });
      window.location.reload();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "becomeAnonModerator", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "becomeAnonModerator"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "becomeMasterUser", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "becomeMasterUser"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AnonymousModeratorTab);
});